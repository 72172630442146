import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/jbzVIbQ2xtg"
    bibleGroupSrc="https://pdfhost.io/v/JBdEsW5vH_Bible_Group_Homework_321pdf.pdf"
    bibleGroupText="Bible Group Homework"
  >
    <SEO title="For Peace - Searching..." />
  </Layout>
)

export default SermonPost
